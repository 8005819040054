<template>
  <div>
    <v-col cols="12">
      <v-card>
        <v-col cols="12" class="pl-0 pr-0 pb-1">
          <v-row>
            <v-spacer />
            <v-col cols="3" sm="2" md="2" class="pr-3">
              <span class="pr-3"> <h3>İnverter verileri</h3> </span>
            </v-col>
            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-select
                v-model="santral"
                :items="santralDataList"
                label="Santral"
              />
            </v-col>

            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-select
                v-model="inverter"
                :items="inverterList[santral]"
                label="İnverter"
              />
            </v-col>

            <v-col cols="3" sm="2" md="2" class="pr-3">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="startDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDate"
                    label="Picker in menu"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker v-model="startDate" no-title scrollable>
                  <v-spacer />
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.menu.save(startDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="3" sm="1" md="1" class="pr-3">
              <v-btn color="primary" @click="getDailyMinuteEnergy">
                Filtrele
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-for="measure in measureList"
          ref="renderComponent"
          :key="measure.value"
          cols="12"
        >
          <template v-if="chartData[measure.value]">
            <echart
              :id="measure.value + '-inverter-value'"
              class-name="widget-content"
              height="350px"
              width="100%"
              :chart-data="chartData[measure.value]"
            />
            <v-divider class="" />
          </template>
        </v-col>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import * as LineChartOption from "@/api/Inverter/ChartOption";
import moment from "moment";
import Echart from "@/views/components/Echart/Chart.vue";
import i18n from "@/locale";
import helper from "@/api/helper";
export default {
  name: "Dashboard",
  components: {
    Echart,
  },
  data: () => ({
    inverter: null,
    startDate: moment().format("YYYY-MM-DD"),
    dailydata: {},
    monthlydata: {},
    yearlydata: {},
    year: moment().format("YYYY"),
    menu: false,
    modal: false,
    menu2: false,
    chartData: {},
    measureList: [],
    inverterList: [],
    santral: "",
    santralInfo: [],
    inverterListData: {},
    loading: false,
  }),
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    santralDataList() {
      const { santralDataList } = this.$store.getters;
      if (Object.keys(santralDataList).length > 0) {
        return santralDataList;
      }
    },
  },
  watch: {
    inverter() {
      this.chartData = {};
      this.measureList = this.inverterListData[this.santral][this.inverter];
      if (this.loading === false) {
        this.getDailyMinuteEnergy();
      }
    },
  },
  created() {},
  mounted() {
    const myVar1 = setInterval(myTimer1, 1000);

    const self = this;
    let k = 0;
    function myTimer1() {
      if (k > 30) {
        clearInterval(myVar1);
      }
      k += 1;
      const { santral } = self.$store.getters.selectedSantralModel;
      const { santralInverterData } = self.$store.getters;

      if (
        santral !== undefined &&
        Object.keys(santralInverterData).length > 0
      ) {
        clearInterval(myVar1);

        self.santral = santral;
        self.setSantralParameters();
      }
    }
  },

  methods: {
    setSantralParameters() {
      const measureList = {};
      const mList = [];
      const inverterList = {};
      const inverterListData = {};
      const formule = ["avg", "sum"];
      const { santralInverterData } = this.$store.getters;

      this.loading = true;
      this.inverterCount = 0;
      Object.keys(santralInverterData).forEach((santral) => {
        inverterListData[santral] = {};
        inverterList[santral] = [];

        Object.keys(santralInverterData[santral]).forEach((i) => {
          inverterList[santral].push({
            text: santralInverterData[santral][i].INVERTERNAME,
            value: santralInverterData[santral][i].INVERTER,
          });
          if (this.inverter == null) {
            this.inverter = santralInverterData[santral][i].INVERTER;
          }
          const rawmeasures =
            santralInverterData[santral][i].general_measure_list;
          const listmeasure = this.setArrayAddKeyToObject(rawmeasures, "value");

          inverterListData[santral][santralInverterData[santral][i].INVERTER] =
            this.setMeasureList(listmeasure);
          Object.keys(rawmeasures).forEach((k) => {
            const measure = rawmeasures[k];
            if (
              mList.indexOf(measure.value) === -1 &&
              formule.indexOf(measure.formuletype) > -1
            ) {
              mList.push(measure.value);
              measureList[measure.value] = {
                text: i18n.t(measure.text),
                value: measure.value,
                unit: measure.unit,
                type: measure.type,
              };
            }
          });
        });
      });
      this.measures = this.setMeasureList(measureList);
      this.inverterList = inverterList;
      this.inverterListData = inverterListData;
      this.loading = false;
      this.getDailyMinuteEnergy();
    },

    setArrayAddKeyToObject(value, key) {
      const newObject = {};
      Object.keys(value).forEach((i) => {
        newObject[value[i][key]] = value[i];
      });

      return newObject;
    },

    setMeasureList(measures) {
      const measureList = [
        "ACPower",
        "DailyPower",
        "TheoreticalPower",
        "PerformanceRatio",
        "Efficiency",
        "TotalEnergy",
        "m1ACPower",
        "m2ACPower",
        "m3ACPower",
        "ACVoltageR",
        "ACVoltageS",
        "ACFrequency",
        "TempBoard",
        "ACCurrentR",
        "ACCurrentS",
        "ACCurrentT",
        "ACPowerReactive",
        "DCPower",
      ];
      const newMeasures = [];
      measureList.forEach((item) => {
        if (helper.isObjectKeysExist(measures, item)) {
          newMeasures.push(measures[item]);
        }
      });
      return newMeasures;
    },
    getDailyMinuteEnergy() {
      this.finishDate = moment(this.startDate).format("YYYY-MM-DD");

      const params = {
        condiniton: {
          startDate: this.startDate,
          finishDate: this.finishDate,
          santral: this.santral,
          inverter: this.inverter,
        },
      };

      // const themes = this.$vuetify.theme.themes.light;
      const tempData = LineChartOption.default.getInverterLiveData(
        params,
        this.measureList
      );
      tempData.then((res) => {
        this.chartData = res;
      });
    },

    forceRerender() {
      this.renderComponent = false;
      this.$nextTick().then(() => {
        this.renderComponent = true;
      });
    },
  },
};
</script>
